import { DropdownModel } from '../geral/DropdownModel';
import _ from 'lodash';
export class ModeloRelatorioModel {
  constructor({
    id,
    codigo,
    titulo,
    relatorioCategoriaId,
    adicionarArquivoRelatorio,
    arquivoRelatorio,
    endpoint,
    descricao,
    parametros = [],
    tipoRelatorio = 'Usuario',
  }) {
    this.id = id;
    this.codigo = codigo;
    this.titulo = titulo;
    this.relatorioCategoriaId = relatorioCategoriaId;
    this.adicionarArquivoRelatorio = adicionarArquivoRelatorio;
    this.arquivoRelatorio = arquivoRelatorio;
    this.parametros = parametros;
    this.jsonModelo = {};
    this.endpointId = null;
    if (endpoint) {
      this.endpointId = new DropdownModel(endpoint);
      this.jsonModelo = null;
      if(endpoint.jsonModelo || endpoint.jsonSql) this.jsonModelo = JSON
        .parse(endpoint.api != 'Sql' ? endpoint.jsonModelo : endpoint.jsonSql);
      this.parametros = endpoint.endpointsParametros;
    }
    this.descricao = descricao;
    this.tipoRelatorio = tipoRelatorio;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.endpointId = retorno.endpointId.value;
    retorno.arquivoRelatorio = retorno.arquivoRelatorio.replace(
      'data:application/json;base64,',
      ''
    );
    return retorno;
  }
}
