<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-2"
        :label="$t('modulos.modelo_relatorio.formulario.codigo')"
        obrigatorio
        trim
      />
      <input-text
        v-model="form.titulo"
        class="col-12 col-md-4"
        :label="$t('modulos.modelo_relatorio.formulario.titulo')"
        obrigatorio
        trim
      />
      <input-text
        :value="buscarTextoTipoRelatorio(form.tipoRelatorio)"
        class="col-12 col-md-2"
        :label="$t('modulos.modelo_relatorio.formulario.tipoRelatorio')"
        disabled
      />
      <input-select
        v-model="form.relatorioCategoriaId"
        class="col-12 col-md-4"
        :label="$t('modulos.modelo_relatorio.formulario.categoria')"
        :options="opcoes.categoria"
        :loading="loadingCategoria"
        obrigatorio
      />

      <input-textarea
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.modelo_relatorio.formulario.descricao')"
        :placeholder="$t('modulos.modelo_relatorio.formulario.descricao')"
        :max="2000"
      />
      <input-endpoints
        v-model="form.endpointId"
        class="col-12"
        :label="$t('modulos.modelo_relatorio.formulario.endpoints')"
        obrigatorio
        @alterado="buscarParametros"
      />
      <accordion-padrao
        v-if="form.endpointId"
        :titulo="$t('modulos.modelo_relatorio.formulario.parametros')"
        class="col-12"
      >
        <tabela-padrao
          :mostrar-checkbox="false"
          sem-paginacao
          :dados="form.parametros"
          :colunas="colunasEndpoint"
          sem-acoes
        >
          <template v-slot:item.obrigatorio="{ item }">
            <div class="d-flex">
              <div
                v-if="item.value"
                class="ma-1 red--text"
              >
                {{ $t('geral.titulos.obrigatorio') }}
              </div>
            </div>
          </template>
        </tabela-padrao>
      </accordion-padrao>
      <input-file-drag-n-drop
        v-model="form.arquivo"
        class="col-12"
        :label-botao="
          $t('modulos.modelo_relatorio.formulario.botao_input_file')
        "
        :label-texto="
          $t('modulos.modelo_relatorio.formulario.texto_input_file')
        "
        prevent-file-system
        @abrir-file-system="abrirStimusoft"
        @drop="abrirStimusoftDrop"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>

    <div
      ref="overlayHeader"
      class="header-overlay-stimusoft"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="white--text ml-4">
          Editando modelo
        </div>
        <botao-padrao
          text
          color="white"
          @click="fecharEditor"
        >
          <v-icon>$close</v-icon>
        </botao-padrao>
      </div>
    </div>
    <div
      ref="overlay"
      class="overlay-stimusoft"
    >
      <div class="editor">
        <stimulsoft-editor
          ref="editor"
          @salvar="atualizarModelo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { StimulsoftEditor } from '@components/misc';
import {
  CategoriaRelatorioService,
  RelatorioService,
  EndpointsService,
} from '@common/services';
import { ModeloRelatorioModel } from '@common/models/cadastros/ModeloRelatorioModel';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { InputEndpoints } from '@components/inputs';
import helpers from '@common/utils/helpers';
export default {
  components: {
    InputEndpoints,
    StimulsoftEditor,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new ModeloRelatorioModel({}),
      loadingCategoria: false,
      opcoes: {
        categoria: [],
        endpoint: [],
        tipoRelatorio: helpers.TipoRelatorioEnum,
      },
      colunasEndpoint: [
        {
          value: 'nome',
          text: this.$t('modulos.modelo_relatorio.tabela.nome_parametro'),
          sortable: true,
        },
        {
          value: 'tipoParametro',
          text: this.$t('modulos.modelo_relatorio.tabela.tipo_parametro'),
          sortable: true,
        },
        {
          value: 'obrigatorio',
          text: this.$t('modulos.modelo_relatorio.tabela.obrigatorio'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.modelo_relatorio.titulos.editar');
      return this.$t('modulos.modelo_relatorio.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      if (!this.form.arquivoRelatorio)
        this.$t(
          'modulos.modelo_relatorio.validacoes.necessario_criar_relatorio'
        );

      return this.$t('modulos.modelo_relatorio.validacoes.formulario_invalido');
    },
  },
  mounted() {
    this.buscarCategoria();
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Relatorio', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'Relatorio', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    this.$watch(
      'form',
      function () {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
        if (!this.form.arquivoRelatorio) this.valido = false;
      },
      { deep: true }
    );
  },
  methods: {
    buscarTextoTipoRelatorio(){
       const text = this.opcoes.tipoRelatorio?.find(el => el.value == this.form.tipoRelatorio)?.text ?? 'Usuário'
       return text
    },
    buscarCategoria: function () {
      this.loadingCategoria = true;
      CategoriaRelatorioService.listar()
        .then((res) => {
          this.opcoes.categoria = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.loadingCategoria = false;
        });
    },
    buscarParametros: function () {
      setTimeout(() => {
        EndpointsService.buscar(this.form.endpointId.value).then((res) => {
          this.form.parametros = res.data.endpointsParametros;
          this.form.jsonModelo = res.data.api != 'Sql' ? res.data.jsonModelo : res.data.jsonSql;
          console.log(this.form.jsonModelo)
        });
      }, 400);
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.buscar(this.id)
        .then((res) => {
          this.form = new ModeloRelatorioModel(res.data);
          this.valido = true;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'modelo-relatorio' });
          this.toastSucesso(
            this.$t(`modulos.modelo_relatorio.cadastro_sucesso`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'modelo-relatorio' });
      });
    },
    abrirStimusoftDrop: function (arquivos) {
      this.form.arquivoRelatorio = arquivos[0].base64.replace(
        'data:application/json;base64,',
        ''
      );
      this.abrirStimusoft();
    },
    abrirStimusoft: function () {
      setTimeout(() => {
        this.$refs.overlayHeader.style.display = 'block';
        this.$refs.overlay.style.display = 'block';
        this.$refs.editor.carregarDataSource(this.form.jsonModelo);
        window.scrollTo(0, 0);
        document.documentElement.style.overflow = 'hidden';
        if (this.form.arquivoRelatorio) {
          this.$refs.editor.montarComponente(this.form.arquivoRelatorio);
        } else {
          this.$refs.editor.montarComponente();
        }
      }, 200);
    },
    fecharEditor: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.modelo_relatorio.confirmar_fechar_editor')
      )
        .then(() => {
          this.$refs.editor.salvar();
          this.esconderEditor();
        })
        .catch(() => {
          this.esconderEditor();
        });
    },
    atualizarModelo: async function (json) {
      this.form.arquivoRelatorio = json;
      this.esconderEditor();
    },
    esconderEditor: function () {
      document.documentElement.style.overflow = 'auto';
      this.$refs.overlay.style.display = 'none';
      this.$refs.overlayHeader.style.display = 'none';
    },
  },
};
</script>
<style lang="scss">
.overlay-stimusoft {
  position: fixed;
  top: 36px;
  left: 0;
  width: 100%;
  height: calc(100vh - 36px);
  display: none;
  z-index: 10;
}
.header-overlay-stimusoft {
  display: none;
  background-color: #19478a;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
</style>
